<template>

  <v-card :loading="loading">
    <v-card-title>
      <span class="headline">New password</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>   
          <v-col cols="12">
            <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="passwordConfirm"
              label="Confirm password"
              type="password"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showStatus">
          <div v-if="!status">
            <v-alert dense outlined type="error">
              <p v-for="e in errors" :key="e">{{ e }}</p>
            </v-alert>
          </div>
          <div v-else>
            <v-alert dense text type="success">Password has been changed. You are being logged in.</v-alert>
          </div>
        </v-row>
      </v-container>
      <small>*Mandatory fields</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="submit">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import accountService from "@/services/AccountService";



export default {
  props: {
    email_prop: { default: null, type: String },
    token_prop: { default: null, type: String }
  },
  data: () => ({
    dialog: false,
    password: "",
    passwordConfirm: "",
    showStatus: false,
    status: false,
    errors: [],
    loading: false
  }),

  computed: {
   
  },
  mounted() {
    this.email = this.email_prop;
  },
  methods: {
    async submit() {
      this.loading = true;

      const request = {
        username: this.email_prop,
        password: this.password,
        token: this.token_prop,
      };

      const response = await accountService.SetPasswordWithToken(request);

      if (!response.succeeded) {
        this.loading = false;
        this.errors = response.errors;
        this.showStatus = true;
        return;
      }

      //login
      const token = await accountService.Login(this.email, this.password);
      if (!token) {
        this.errors.push("Something went wrong trying to log in");
        this.showStatus = true;
        return;
      }

      this.$store.dispatch("auth/setCredentials", token);
      Vue.prototype.$axios.defaults.headers.common["Authorization"] =
        "Bearer " + token;

      this.status = true;
      this.showStatus = true;

      setTimeout(() => {
        this.$router.replace({ name: "home" });
      }, 1000);
    },

    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    }
  }
};
</script>

