<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">

          <a color="green" dark
           v-bind="attrs"
          v-on="on"
          >Forgot your password?</a>
        <!-- <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn> -->
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Reset password
        </v-card-title>

        <v-card-text>
               <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

            <v-alert v-show="toggleAlert" :type="alertType">
                {{ alert}}
            </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
                   <v-btn
                   small
            color="error"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
          small
            color="primary"
            @click="ResetPassword"
            :loading="loading"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import accountService from "@/services/AccountService";

  export default {
    data () {
      return {
        loading:false,
        dialog: false,
        toggleAlert: false,
        alertType: "success",
        alert: "",
        email: "",
        emailRules: [
        v => !!v || 'E-mail missing',
        v => /.+@.+/.test(v) || 'E-mail is not valid',
      ],
      }
    },
    methods: {
    async ResetPassword() {
        this.loading = true;
      this.toggleAlert = false;
      const result =  await accountService.ResetPassword(this.email)
      this.alertType = result.succeeded ? "success" : "error";
      this.alert = result.succeeded ? "A new password has been sent to  " + this.email : result.errors;
      this.toggleAlert = true;
      this.loading = false;
      
        window.analytics.track('Reset Password', {
          title: 'Reset Password',
          email: this.email,
          result: result
        });
      
    }

    },
  }
</script>