<template>
  <v-footer
      dark
      padless
      absolute
    
    
  >
    <v-card
      flat
      tile
      class="white--text text-center"
      width="100%"
      color="primary"
    >



      <v-divider></v-divider>

      <v-card-text class="white--text">
        PRECIS
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
    }),
  }
</script>