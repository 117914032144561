<template>
    <v-sheet>
      <v-container fill-height>
        <v-row justify="center" align="center">
             <v-col lg="4" md="6" cols="12">
              <div>
                <resetpasswordform :email_prop="email" :token_prop="token" />
              </div>
          </v-col>
        </v-row>

      </v-container>
    </v-sheet>
</template>

<script>
import resetpasswordform from "@/components/auth/ResetPasswordForm.vue";

export default {
  components: {
    resetpasswordform
  },

  data: () => ({
    errors: [],
  }),
  computed: {
    token() {
      return this.$route.query.token;
    },
    email() {
      return this.$route.query.email;
    }
  }
};
</script>

