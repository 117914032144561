<template>
  <div>
<span class="bg"></span>
    <v-main color="primary">
      <v-container fill-height>
        
        <v-img class="my-5" contain height="80" src="../../assets/Preem_logo_RGB.png"/>
        <v-row justify="center" align="center">
          
          
          
          <v-col lg="3" md="6" sm="6" xs="2">
            <v-card class="elevation-12" rounded>
              <v-card-title>
                Log in
              </v-card-title>
              <v-card-text>
                <v-form v-on:submit.prevent="Login">
                  <v-text-field
                    label="E-mail"
                    name="login"
                    prepend-icon="mdi-email"
                    type="text"
                    v-model="email"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                  ></v-text-field>

                  <v-card-actions class="mt-0 mb-0 pt-0 pb-0"> 
                    <v-container class="mt-0 mb-0 pt-0 pb-0">
                      <v-row dense >
                        <v-col>
                          <v-btn
                            :loading="loading"
                            type="submit"
                            class="mt-0"
                            block
                            height="50"
                            dark
                            color="primary"
                          >
                           <v-icon
        left
        dark
       
      >
      mdi-login
      </v-icon>
                          {{ submitText }}</v-btn>
                          
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <v-slide-x-transition>
                            <div class="font-weight-regular red--text"
                              v-if="showAlert"
                              dense
                              border="left"
                              :class=" { status : 'green--text'}"
                            >
                            <v-icon left small v-if="status">mdi-check</v-icon>
                              {{ statusText }}
                            </div>
                          </v-slide-x-transition>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <v-divider inset />
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <resetpassword />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-spacer/>
    <loginfooter />
  </div>
</template>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: url( "../../assets/logo.png") no-repeat center center; */
    background-size: cover;
    /* transform: scale(1.1); */
  }
</style>


<script>
import Vue from 'vue'
import accountService from "@/services/AccountService";
// import ResetPassword from "@/components/auth/ResetPassword.vue";
import loginfooter from "@/components/navigation/LoginFooter.vue";
import resetpassword from "@/components/auth/ResetPassword.vue"


export default {
  props: {
    source: String
  },
  components: {
    // ResetPassword,
    loginfooter,
    resetpassword
  },
  data: () => ({
    email: null,
    password: null,
    showAlert: false,
    status: false,
    statusText: "",
    loading: false,
    submitText: "Log in",
  }),
  methods: {
    async Login() {
      this.showAlert = false;
      this.loading = true;
      const token = await accountService.Login(this.email, this.password);
      if (!token) {
        this.status = false;
        this.statusText = "Wrong username or password";
      }
      else{
      this.status = true
      this.statusText = "You are being logged in";
      this.$store.dispatch('auth/setCredentials', token);
      Vue.prototype.$axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      this.showAlert = true;
      this.loading = false;
      if(this.status){
          setTimeout( () => {
              this.$router.replace({ name: "home" });
          }, 1000);
      }
      
     
    }
  }
};
</script>