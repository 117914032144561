<template>
  <v-sheet>
    <v-sheet color="grey lighten-5" dark outlined height="170">
      <v-container>
        <v-row justify="start" dense align="center" class="mt-4">
          <v-col cols="1">
            <v-img contain width="100%" height="60" src="../assets/Preem_logo_RGB.png" />
          </v-col>
          <v-col cols="3">
            <p
              class="transition-swing text-h3 black--text font-weight-medium text-left"
              style="width:190px"
            >PRECIS</p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="#EBE9E5" class="overflow-hidden pb-10" style="position: relative;">
      <v-container fill-height>
        <v-row justify="start" v-for="(m,n) in menu" :key="n">
          <v-col cols="12">
            <div class="transition-swing text-h5 mb-0">{{m.group}}</div>
          </v-col>
          <div  v-for="(c, i) in m.cards" :key="i">
          <v-card
            :to="c.to"
            width="250px"
            height="120px"
            :disabled="c.disabled || !isInRole(c.role)"
            class="mt-2 mx-2"
          >
            <v-toolbar dense height="10" color="info"></v-toolbar>
            <v-card-title v-text="c.title" class="mt-1 py-0"></v-card-title>
            <v-divider class="my-1 py-0"/>
            <v-card-subtitle  class="my-0 py-0">
              {{c.text}}
            </v-card-subtitle>
<v-card-actions>
  <v-spacer />
  <v-icon>{{c.icon}}</v-icon>
</v-card-actions>
                        
          </v-card>
          </div>
        </v-row>
      </v-container>
    </v-sheet>
  </v-sheet>
</template>


<script>
export default {
  data: () => ({
    chart: null,
    loadingChart: false,
    menu: [
      {
        group: "Reports",
        cards: [
          {
            title: "Reports",
            to: "/reports",
            text: "External Analysis",
            color: "#1F7087",
            disabled: false,
            role: "Reports",
            icon: "mdi-table"
          },
          {
            title: "Pricing",
            to: "/pricing",
            text: "Merchant and product sales",
            color: "#1F7087",
            disabled: false,
            role: "Pricing",
            icon: "mdi-table"
          },
          {
            title: "Executive Reports",
            to: "/sales-executive",
            text: "Sales executive reports",
            color: "#1F7087",
            disabled: false,
            role: "Reports",
            icon: "mdi-table"
          }          
        ]
      },
      {
        group: "Marketing and sales",
        cards: [
          {
            title: "Precis",
            to: "/precis",
            text: "Geo Targeting",
            color: "#1F7087",
            disabled: false,
            role: "Precis",
            icon: "mdi-earth"
          },
          {
            title: "Sales",
            to: "/salesrep",
            text: "Sales potential and prospecting",
            color: "indigo lighten-4",
            disabled: false,
            role: "Salesrep",
            icon: "mdi-account"
          },
          {
            title: "Marketing lists",
            to: "/profile",
            text: "Saved marketing lists",
            color: "#1F7087",
            disabled: true,
            role: "Precis",
            icon: "mdi-account"
          }
        ]
      },
      {
        group: "Admin",
        cards: [
          {
            title: "CIMS Admin",
            to: "/cims",
            text: "Customer register",
            color: "indigo lighten-4",
            disabled: false,
            role: "Cims",
            icon: "mdi-account-search"
          }
        ]
      }
    ]
  }),
  mounted() {
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    cardsFiltered() {
      return this.cards.filter(i => {
        return this.isInRole(i.role);
      });
    }
  },
  methods: {
    isInRole(role) {
      var result =  this.user.data.roles.includes(role);
      return result
    }
  }
};
</script>
